/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Box,
  SimpleGrid,
  Text,
  Spacer,
  useColorModeValue,
  FormControl,
  FormLabel,
  Input,
  Flex,
  Button,
  Spinner,
  Alert,
  AlertIcon,
  AlertDescription,
  AlertTitle,
} from "@chakra-ui/react";
import axios from "axios";
import DevelopmentTable from "views/admin/apiKeys/components/DevelopmentTable";
import React from "react";
import Card from "components/card/Card";
import { useSelector } from "react-redux";
import { getToken } from "../../../reducers/tokenTracker";
import { host } from "detect_host";

export default function Settings() {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [keyName, setKeyName] = React.useState(null);
  const [expireIn, setExpireIn] = React.useState(null);
  const [apiKeys, setApiKeys] = React.useState(null);
  const [apiKeysColumns, setApiKeysColumns] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState(null);
  const [keyGenerating, setKeyGenerating] = React.useState(false);

  const token = useSelector(getToken);

  const generate_api_key = () => {
    setKeyGenerating(true);
    if (keyName !== null && keyName.trim() !== "") {
      if (
        expireIn !== null &&
        typeof expireIn !== Number &&
        expireIn > 0 &&
        expireIn <= 365 * 2
      ) {
        axios({
          method: "post",
          url: "/auth/token/api/new",
          baseURL: host(),
          data: { name: keyName, expire_in: expireIn },
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => {
            setAlertMessage({
              message:
                "API key generated, please copy it now. You can't view it later",
              key: response.data.result["X-Api-Key"],
              type: "success",
            });
            get_my_api_keys();
            setKeyGenerating(false);
          })
          .catch((error) => {
            setAlertMessage({
              message: `Error occured: ${error.msg}`,
              key: null,
              type: "error",
            });
            setKeyGenerating(false);
          });
      } else {
        setAlertMessage({
          message: "Please provide a valid expire in",
          key: null,
          type: "error",
        });
        setKeyGenerating(false);
      }
    } else {
      setAlertMessage({
        message: "Please provide a key name",
        key: null,
        type: "error",
      });
      setKeyGenerating(false);
    }
  };

  const get_my_api_keys = () => {
    setLoading(true);
    axios({
      method: "get",
      url: "/auth/token/api/list",
      baseURL: host(),
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (response) => {
        if (response.data.success) {
          if (response.data.result != null && response.data.result.length > 0) {
            const keys = Object.keys(response.data.result[0]);
            let keyColumns = [];
            keys.forEach((key, idx) => {
              keyColumns.push({
                Header: key.toUpperCase(),
                accessor: key,
              });
              if (keys.length - 1 === idx) {
                setApiKeys(response.data.result);
                setApiKeysColumns(keyColumns);
                setLoading(false);
              }
            });
          } else {
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  React.useEffect(() => {
    const get_my_api_keys_on_effect = () => {
      setLoading(true);
      axios({
        method: "get",
        url: "/auth/token/api/list",
        baseURL: host(),
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
      })
        .then(async (response) => {
          if (response.data.success) {
            if (
              response.data.result != null &&
              response.data.result.length > 0
            ) {
              const keys = Object.keys(response.data.result[0]);
              let keyColumns = [];
              keys.forEach((key, idx) => {
                keyColumns.push({
                  Header: key.toUpperCase(),
                  accessor: key,
                });
                if (keys.length - 1 === idx) {
                  setApiKeys(response.data.result);
                  setApiKeysColumns(keyColumns);
                  setLoading(false);
                }
              });
            } else {
              setLoading(false);
            }
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    };
    get_my_api_keys_on_effect();
  }, []);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card>
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          New API Key
        </Text>
        <Spacer mb="20px" />
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "100%" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <FormControl>
            <SimpleGrid columns={{ base: 1, md: 4 }} gap={2}>
              <Box pl="1">
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Key Name<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  type="text"
                  placeholder="My Key"
                  mb="24px"
                  fontWeight="500"
                  size="lg"
                  onChange={(e) => setKeyName(e.target.value)}
                />
              </Box>
              <Box pr="1">
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Expire in (days)<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  type="number"
                  placeholder="30"
                  max={365 * 2}
                  min={1}
                  mb="24px"
                  fontWeight="500"
                  size="lg"
                  onChange={(e) => setExpireIn(e.target.value)}
                />
              </Box>
            </SimpleGrid>
            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="150px"
              h="50"
              mb="24px"
              mx="4px"
              onClick={generate_api_key}
              isLoading={keyGenerating}
            >
              Generate
            </Button>
          </FormControl>
        </Flex>
        {alertMessage !== null ? (
          <Alert status={alertMessage.type}>
            <AlertIcon />
            {/* <Box> */}
            <AlertDescription>{alertMessage.message}:</AlertDescription>
            <AlertTitle>
              &nbsp;&nbsp;&nbsp;&nbsp;<code>{alertMessage.key}</code>
            </AlertTitle>
            {/* </Box> */}
          </Alert>
        ) : null}
      </Card>
      <Spacer mb="10px" />
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        {loading ? (
          <Card>
            <Flex justify="center">
              <Spinner size="xl" />
            </Flex>
          </Card>
        ) : apiKeys === null ? (
          <Card>
            <Text> No API keys to show </Text>
          </Card>
        ) : (
          <DevelopmentTable
            columnsData={apiKeysColumns}
            tableData={apiKeys}
            title={"My Keys"}
            refreshAction={get_my_api_keys}
          />
        )}
      </SimpleGrid>
    </Box>
  );
}
