import React from "react";

// Chakra imports
import { Flex, useColorModeValue, Text, Heading } from "@chakra-ui/react";

// Custom components
import { HSeparator } from "components/separator/Separator";
// import logoTiny from "assets/img/logo/raccoon-ai-tiny.png";

export function SidebarBrand() {
  //   Chakra color mode
  let logoColor = useColorModeValue("navy.700", "white");
  return (
    <Flex direction="column">
      <Heading
        pl={5}
        variant="h1"
        pb="4px"
        fontWeight="750"
        lineHeight="100%"
        color={logoColor}
      >
        RACCOON AI
      </Heading>
      <Text
        pl={5}
        pb="4px"
        style={{ letterSpacing: "7.5px" }}
        color={logoColor}
      >
        CONSOLE
      </Text>
      <HSeparator mb="20px" />
    </Flex>
  );
}

export default SidebarBrand;
