/* eslint-disable */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  Spacer,
  Alert,
  AlertTitle,
  AlertDescription,
  AlertIcon,
  Grid,
  GridItem,
  SimpleGrid,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import axios from "axios";
import { host } from "detect_host";

function SignUp() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");

  const [showP, setShowP] = React.useState(false);
  const [showRtp, setShowRtp] = React.useState(false);
  const handleShowPClick = () => setShowP(!showP);
  const handleShowRtpClick = () => setShowRtp(!showRtp);

  const [email, setEmail] = React.useState(null);
  const [password, setPassword] = React.useState(null);
  const [cPassword, setCPassword] = React.useState(null);
  const [firstName, setFirstName] = React.useState(null);
  const [lastName, setLastName] = React.useState(null);
  const [tel, setTel] = React.useState(null);
  const [alertMessage, setAlertMessage] = React.useState(null);
  const [onRegistering, setOnRegistering] = React.useState(false);

  const tryToRegister = (e) => {
    e.preventDefault();
    setAlertMessage(null);
    setOnRegistering(true);
    if (
      email !== null &&
      password !== null &&
      cPassword !== null &&
      firstName !== null &&
      lastName !== null &&
      tel !== null
    ) {
      if (cPassword === password) {
        axios({
          method: "post",
          url: `/auth/register`,
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          baseURL: `${host()}`,
          data: {
            email: email,
            password: password,
            first_name: firstName,
            last_name: lastName,
            tel: tel,
          },
        })
          .then((response) => {
            if (response.status === 200) {
              if (response.data.success) {
                setAlertMessage({
                  type: "success",
                  message: "Sign up Success!",
                  desc: `Please sign in to continue.`,
                });
                setOnRegistering(false);
                // goto("/");
              } else {
                setAlertMessage({
                  type: "error",
                  message: "Sign up Failed!",
                  desc: response.data.msg,
                });
                setOnRegistering(false);
              }
            } else {
              setAlertMessage({
                type: "error",
                message: "Sign up Failed!",
                desc: "Something went wrong. Please try again. Most likely, required fields are missing.",
              });
              setOnRegistering(false);
            }
          })
          .catch((err) => {
            setAlertMessage({
              type: "error",
              message: "Sign up Failed!",
              desc: err.message,
            });
            setOnRegistering(false);
          });
      } else {
        setAlertMessage({
          type: "error",
          message: "Sign up Failed!",
          desc: "Password and Retype password do not match.",
        });
        setOnRegistering(false);
      }
    } else {
      setAlertMessage({
        type: "error",
        message: "Sign up Failed!",
        desc: "Please fill in all the required fields.",
      });
      setOnRegistering(false);
    }
  };

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "20px", md: "5vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Sign Up
          </Heading>
          <Text
            mb="26px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Enter your details to create your account!
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "15px", md: "auto" }}
        >
          <form onSubmit={tryToRegister}>
            <FormControl onSubmit={tryToRegister}>
              <SimpleGrid columns={{ base: 1, md: 2 }}>
                <Box pr="1">
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    First Name<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <Input
                    isRequired={true}
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: "0px", md: "0px" }}
                    type="text"
                    placeholder="John"
                    mb="12px"
                    fontWeight="500"
                    size="lg"
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </Box>
                <Box pl="1">
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Last Name <Text color={brandStars}>*</Text>
                  </FormLabel>
                  <Input
                    isRequired={true}
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: "0px", md: "0px" }}
                    type="text"
                    placeholder="Doe"
                    mb="12px"
                    fontWeight="500"
                    size="lg"
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </Box>
              </SimpleGrid>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Email <Text color={brandStars}> *</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="email"
                placeholder="mail@example.com"
                mb="12px"
                fontWeight="500"
                size="lg"
                onChange={(e) => setEmail(e.target.value)}
              />
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Telephone Number<Text color={brandStars}> *</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="text"
                placeholder="+9400002320"
                mb="12px"
                fontWeight="500"
                size="lg"
                onChange={(e) => setTel(e.target.value)}
              />
              <SimpleGrid columns={{ base: 1, md: 2 }}>
                <Box pr="1">
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    Password <Text color={brandStars}>*</Text>
                  </FormLabel>
                  <InputGroup size="md">
                    <Input
                      isRequired={true}
                      fontSize="sm"
                      placeholder="Min. 8 characters"
                      mb="12px"
                      size="lg"
                      type={showP ? "text" : "password"}
                      variant="auth"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <InputRightElement
                      display="flex"
                      alignItems="center"
                      mt="4px"
                    >
                      <Icon
                        color={textColorSecondary}
                        _hover={{ cursor: "pointer" }}
                        as={showP ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                        onClick={handleShowPClick}
                      />
                    </InputRightElement>
                  </InputGroup>
                </Box>
                <Box pl="1">
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    Retype Password <Text color={brandStars}>*</Text>
                  </FormLabel>
                  <InputGroup size="md">
                    <Input
                      isRequired={true}
                      fontSize="sm"
                      placeholder="Min. 8 characters"
                      mb="12px"
                      size="lg"
                      type={showRtp ? "text" : "password"}
                      variant="auth"
                      onChange={(e) => setCPassword(e.target.value)}
                    />
                    <InputRightElement
                      display="flex"
                      alignItems="center"
                      mt="4px"
                    >
                      <Icon
                        color={textColorSecondary}
                        _hover={{ cursor: "pointer" }}
                        as={showRtp ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                        onClick={handleShowRtpClick}
                      />
                    </InputRightElement>
                  </InputGroup>
                </Box>
              </SimpleGrid>
              {alertMessage !== null ? (
                <Alert status={alertMessage.type} mb="5">
                  <AlertIcon />
                  <Box>
                    <AlertTitle>{alertMessage.message}</AlertTitle>
                    <AlertDescription>{alertMessage.desc}</AlertDescription>
                  </Box>
                </Alert>
              ) : null}
              <Button
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                type="submit"
                disabled={
                  alertMessage !== null && alertMessage.type === "success"
                    ? true
                    : false
                }
                isLoading={onRegistering}
              >
                Sign Up
              </Button>
            </FormControl>
          </form>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            <Text color={textColorDetails} fontWeight="400" fontSize="14px">
              I have an account
              <NavLink to="/auth/sign-in">
                <Text
                  color={textColorBrand}
                  as="span"
                  ms="5px"
                  fontWeight="500"
                >
                  Sign In
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignUp;
