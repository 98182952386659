import { createSlice } from "@reduxjs/toolkit";

const userDataSlice = createSlice({
  name: "userData",
  initialState: {
    value: { user: null },
  },
  reducers: {
    setUser: (state, action) => {
      state.value.user = action.payload;
    },
  },
});

export const getUser = (state) => state.userData.value.user;
export const { setUser } = userDataSlice.actions;

export default userDataSlice.reducer;
