import React, { useCallback } from "react";
import "assets/css/App.css";
import { Route, Switch, Redirect, HashRouter } from "react-router-dom";
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import {
  AbsoluteCenter,
  Box,
  ChakraProvider,
  Flex,
  Image,
  Spacer,
  Spinner,
  Square,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import theme from "theme/theme";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
import { useSelector, useDispatch } from "react-redux";
import { getLoginStatus, login } from "./reducers/loginTracker";
import { setUser } from "./reducers/userDataTracker";
import { setToken, getToken } from "./reducers/tokenTracker";
import axios from "axios";
import { host } from "detect_host";
import { Provider } from "react-redux";
import { configuredStore, history } from "./store";
import { ConnectedRouter } from "connected-react-router";
import logoTiny from "assets/img/logo/raccoon-ai-tiny.png";

const AdminRoute = () => {
  return (
    <Switch>
      <Route path={`/admin`} component={AdminLayout} />
      {/* <Redirect from="/" to="/admin" /> */}
      <Redirect to="/admin" />
      <Route />
    </Switch>
  );
};

const AuthRoute = () => {
  return (
    <Switch>
      <Route path={`/auth`} component={AuthLayout} />
      {/* <Redirect from="/" to="/auth" /> */}
      <Redirect to="/auth" />
    </Switch>
  );
};

const SplashScreen = () => {
  const borderColor = useColorModeValue("white", "navy.800");
  const bgColor = "linear-gradient(135deg, #868CFF 0%, #4318FF 100%)";
  return (
    <Box
      position="fixed"
      h="100%"
      w="100%"
      bg={bgColor}
      overflowX="hidden"
      overflowY="hidden"
    >
      <AbsoluteCenter
        p="4"
        color="white"
        axis="both"
        // border="5px solid"
        // borderColor={borderColor}
        // borderRadius={20}
        // pl={20}
        // pr={20}
        // pt={5}
        // pb={5}
        align="center"
        justify="center"
        mx="auto"
        zIndex={10}
      >
        <Image src={logoTiny} w="70px" h="70px" />
        <Spacer mb={10} />
        <Spinner />
      </AbsoluteCenter>
    </Box>
  );
};

const Router = () => {
  const [isValidated, setIsValidated] = React.useState(false);
  const token =
    sessionStorage.getItem("token") || localStorage.getItem("token");
  const dispatch = useDispatch();
  const goto = useCallback((path) => history.push(path), [history]);

  React.useEffect(() => {
    setIsValidated(false);
    axios({
      method: "get",
      url: `/auth/users/me`,
      baseURL: host(),
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 200 && response.data.success) {
          dispatch(setUser(response.data.result));
          dispatch(login());
          dispatch(setToken(token));
          setIsValidated(true);
          goto("/admin");
        } else {
          goto("/auth");
        }
      })
      .catch((_) => {
        localStorage.removeItem("token");
        sessionStorage.removeItem("token");
        setIsValidated(true);
        goto("/auth");
      });
  }, []);
  return useSelector(getLoginStatus) ? (
    <AdminRoute />
  ) : !isValidated ? (
    <SplashScreen />
  ) : (
    <AuthRoute />
  );
};

const App = () => {
  const store = configuredStore();

  return (
    <ChakraProvider theme={theme}>
      <React.StrictMode>
        <ThemeEditorProvider>
          <Provider store={store}>
            <ConnectedRouter history={history}>
              <Router />
            </ConnectedRouter>
          </Provider>
        </ThemeEditorProvider>
      </React.StrictMode>
    </ChakraProvider>
  );
};

export default App;
